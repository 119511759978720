import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        borderRadius: '16px',
        padding: '24px',
        width: '500px',  // Set fixed width to 500px
        maxWidth: '100%', // Ensure it doesn't overflow on smaller screens
        position: 'absolute',
        right: 0,
        top: 0,
    },
});

const LogoImage = styled('img')({
    width: '96px',
    height: '96px',
    objectFit: 'contain',
    marginBottom: '16px',
});

const Link = styled('a')({
    color: '#1E3A8A', // Tailwind's blue-900
    textDecoration: 'underline',
    fontWeight: 'bold',
    transition: 'color 0.3s',
    '&:hover': {
        color: '#1D4ED8', // Tailwind's blue-700
    },
});

function DialogBookingDetails({ isDialogOpen, bookingInfo, handleCloseDialog }: any) {

    const getSiteLink = (link: string) => {
        if (!link) { return '#'; }
        const arr = link.split(':');
        if (arr.length === 1) {
            return 'https://' + link;
        } else {
            return link;
        }
    }

    return (
        <StyledDialog open={isDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle className="flex flex-col justify-center items-center">
                <LogoImage
                    src={`${process.env.REACT_APP_BACKEND_URL}/${bookingInfo.company?.logo}`}
                    alt={`${bookingInfo.company?.name} Logo`}
                />
                <Typography variant="h6" className="font-bold text-blue-900">
                    {bookingInfo.company?.name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="space-y-4">
                    <div className="flex justify-between items-center space-x-4">
                        <Typography variant="body2" className="font-semibold text-gray-600 mx-1">
                            Booth No
                        </Typography>
                        <Typography variant="body2" className="text-gray-800">
                            {bookingInfo.booth}
                        </Typography>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center space-x-4">
                        <Typography variant="body2" className="font-semibold text-gray-600 mx-1">
                            Company Description
                        </Typography>
                        <Typography variant="body2" className="text-gray-800">
                            {bookingInfo.company?.description}
                        </Typography>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center space-x-4">
                        <Typography variant="body2" className="font-semibold text-gray-600 mx-1">
                            Website
                        </Typography>
                        <Link
                            href={getSiteLink(bookingInfo.company?.website_social_media)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {bookingInfo.company?.website_social_media}
                        </Link>
                    </div>
                </div>
            </DialogContent>
        </StyledDialog>
    );
}

export default DialogBookingDetails;
